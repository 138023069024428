import React from 'react';
import Layout from '../../../components/Layout';
import logger from 'utils/logger';

import { Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';
import SimpleHeader from 'components/Headers/SimpleHeader';
// import MediaFilters from 'components/Navigation/MediaFilters';

import FormContact from 'components/CTAs/FormContact';

class GeneralForms extends React.Component {
  render () {
    logger.template(__filename, '>', 'render()');
    logger.templateEnd();

    return (
      <Layout>
        <Fluid>
          <SimpleHeader title={`Contact Us`} />
          <section className={`ptb-20em`}>
            <Offset>
              <Row>
                <Col>
                  <FormContact />
                </Col>
              </Row>
            </Offset>
          </section>
        </Fluid>
      </Layout>
    );
  }
}
export default GeneralForms;
